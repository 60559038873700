@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700&family=Sora:wght@100..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Setting up base font size and family */
body {
  font-family: 'Sora', sans-serif;
  font-size: 16px;
  background-color: #fff;
  color: #242424;
  min-height: 100vh;
  min-width: 350px;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Sora', sans-serif;
}
h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  margin-bottom: 0.75rem;
  font-weight: 700;
}

h3 {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

h4 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

h5 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

/* Paragraphs */
p {
  margin-bottom: 1rem;
  opacity: 80%;
}

/* Links */
a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
/* Other */
ul {
  list-style: none;
}
/* Media Queries */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 2rem;
  }

  /* Additional responsive styles */
}
