.order .cancel-button{
  background-color: #e62e28;
}
.go-back, .edit-toggle{
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  border-radius: 8px;
  font-size: 16px;
  background: none;
  border: none;
  color: #333;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 10px;
}
.buttons{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.buttons > div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.save-button{
  position: fixed;
  bottom: 10px;
  right: 20px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 32px;
  padding: 15px 20px;
  border: none;
}
.edit-toggle{
    width: 80px;
    height: 35px;
    background-color: #e0e0e0;
    border-radius: 18px;
    position: relative;
    padding: 3px;
    transition: all 400ms ease;
}
.edit-toggle > .circle{
    position: absolute;
    width: fit-content;
    color: #333;
    padding: 0 8px;
    transition: all 400ms ease;
    height: 30px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    color: #a5a5a5;
}
.edit-toggle:hover {
    background-color: #e0e0e0;
}
.edit-toggle.edit > .circle{
    transition: all 400ms ease;
    transform: translateX(30px);
    color: #333;
}

.edit-toggle.edit {
  color: #ffffff;
  background-color: #333;
}

.edit-toggle.edit:hover {
}

/* .edit-toggle span {
  display: flex;
  align-items: center;
  gap: 8px;
} */