.content_wrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  padding: 10px;
  gap: 10px;
  max-width: 1800px;
  height: 100svh;
  overflow: hidden;
  margin: 0 auto;
  font-family: 'Sora', sans-serif;
}
.content_wrapper > main {
  height: calc(100% - 70px);
}
nav {
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  height: 60px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.main_page{
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  min-height: 600px;
  height: calc(100svh - 88px);
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
  padding: 10px;
}
button{
  padding: 6px 10px;
  cursor: pointer;
  background-color: #242424;
  border-radius: 16px;
  color: #e3e3e3;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

@media screen and (max-width: 900px) {
  .content_wrapper{
    overflow: auto;
  }
}