.side_bar {
  width: 100%;
  height: calc(100vh - 20px);
  overflow-y: scroll;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  padding: 10px;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  gap: 10px;
  text-align: right;
}
.logo > svg {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  filter: saturate(0);
}
.logo p {
  font-size: 0.7rem;
  font-weight: 200;
  margin: 0;
}
.logo h3 {
  margin: 0;
  font-size: 1rem;
}
.profile {
  height: 30px;
  width: 30px;
  background: linear-gradient(to bottom right, purple, lightblue);
  border-radius: 50%;
}
.side-nav {
  margin: 20px 0;
}

.side-nav > li.active {
  background-color: #fafafa;
  border-radius: 12px;
}
.side-nav > li .side-nav-item {
  list-style: none;
  display: grid;
  grid-template-columns: 20px 1fr auto;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
  padding: 10px 5px;
  border-radius: 12px;
  cursor: pointer;
}
.side-nav > li .side-nav-item:hover {
  background-color: #fafafa;
}
.side-nav > li a span {
  font-size: 12px;
  background-color: #efefef;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c7c7c7;
  border-radius: 6px;
  border: #c7c7c7 1px solid;
}
.side-nav-sub-item{
  padding-left: 30px;
  position: relative;
}
.side-nav-sub-item li{
  padding: 6px 4px;
  font-size: 14px;
  color: #545454;
}
.side-nav-sub-item li:hover{
  background-color: rgba(0,0,0,0.01);
}
.side-nav-sub-item::after{
  content: '';
  height: calc(100% - 10px);
  width: 2px;
  position: absolute;
  top: 0;
  left: 15px;
  background-color: #1f1f1f;
  opacity: 0.2;
}
.folders_wrapper {
  margin-top: 30px;
}
.folders_wrapper > h6 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 400;
  color: #898989;
}
.folders_wrapper > h6 span {
  font-size: 24px;
  cursor: pointer;
}
.folder {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  margin: 5px 0;
}
.folder > p {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.folder:hover {
  background-color: #fafafa;
}
.folder.active {
  background-color: #fafafa;
}
.inside {
  opacity: 70%;
}
.inside li {
  font-size: 14px;
  color: #383838;
  padding: 5px;
  margin: 5px 0;
}
.inside li:hover {
  background-color: #fff;
}
.inside li span {
  font-size: 12px;
  background-color: #efefef;
  padding: 2px 4px;
  color: #c7c7c7;
  border-radius: 6px;
  border: #c7c7c7 1px solid;
  margin-right: 10px;
}