.payments table{
    border-top-left-radius: 0px;
}
.payment-tabs-heading{
    display: flex;
    gap: 4px;
    margin-left: 0px;
    margin-bottom: -1px;
}
.payment-tabs-heading > div{
    padding: 10px;
    /* background-color: #f3f3f3; */
    border-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100px;
    border: 1px solid #e3e3e3;
    cursor: pointer;
}
.payment-tabs-heading > div.active{
    background-color: #fafafa;
    border-bottom: none;
}