table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 12px;
  overflow: hidden;
  font-size: clamp(12px, 1.2vw, 16px);
  border-collapse: separate;
  border: 1px solid #ececec
}
th,
td {
  padding: 8px;
  text-align: left;
      border-left:solid #ececec 1px;
    border-top:solid #ececec 1px;
}
th {
  background-color: #fafafa;
  width: max-content;
    border-top: none;
}
td:first-child, th:first-child {
     border-left: none;
}
table .brand {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
}
td a{
  color: #e62e28;
  text-decoration: underline;
}
td > svg{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  width: auto
}
td input{
  height: 30px;
  width: 100%;
  padding: 0 10px;
  border: none;
  font-size: clamp(12px, 1.2vw, 16px);
}
td input:read-only{
  opacity: 70%;
  cursor: not-allowed;
}
td input:read-only ~ svg{
  opacity: 60%;
}
table button{
  padding:  10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  background-color: #333;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}