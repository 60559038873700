.create-order select{
    width: 100%;
    padding: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 12px;
    background-color: #f3f3f3;
}
.create-order td{
    max-height: 100px;
    overflow-y: auto;
}
.slots{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 10px;
}
.slot{
    border: 1px solid #e3e3e3;
    padding: 10px 10px;
    height: 40px;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
}
.slot.active{
    background-color: #343434;
    color: #ffffff;
}
.slot.disabled{
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}
.order-created{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}
.react-datepicker__calendar-icon{
    position: absolute;
    right: 0;
}
.react-datepicker-wrapper{
    width: 100%;
}
.react-datepicker__view-calendar-icon input{
    padding: 5px !important;
}