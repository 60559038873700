.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}
.filters > div {
  display: flex;
  gap: 10px;
}
.filters > div > div {
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.filters .search {
  display: flex;
  align-items: center;
  position: relative;
}
.filters .search svg {
  position: absolute;
  left: 5px;
}
.filters input {
  height: 30px;
  border: #c7c7c7 1px solid;
  border-radius: 12px;
  padding: 0 10px;
  padding-left: 30px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
}