nav {
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  height: 60px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
nav h4 {
  margin-bottom: 0;
  font-size: 18px;
}
.search_wrapper {
  display: flex;
  gap: 20px;
}
.search_wrapper > div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
nav .search {
  display: flex;
  align-items: center;
  position: relative;
}
nav .search svg {
  position: absolute;
  left: 5px;
}
nav input {
  height: 30px;
  border: #c7c7c7 1px solid;
  border-radius: 12px;
  padding: 0 10px;
  padding-left: 30px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
}